import { AppProps } from 'next/app'
import Head from 'next/head'
import 'tailwindcss/tailwind.css'
import 'styles/global.css'
function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>Intelligov</title>
        <link rel="shortcut icon" href="/img/icon-512.png" />
        <link rel="apple-touch-icon" href="/img/icon-512.png" />
        <link rel="manifest" href="/manifest.json" />
        <meta
          name="description"
          content="A melhor aplicação para controle de contratos, gestão de sociedade e muito mais!"
        />
      </Head>
      <Component {...pageProps} />
    </>
  )
}

export default App
